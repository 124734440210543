import React from "react"
import * as ReactDOM from "react-dom/client"
import reactToWebcomponent from "react-to-webcomponent"
import { ContactEpitechDigital, DocumentationEpitechDigital, DigiclubEpitechDigital, JourneesPortesOuvertesEpitechDigital, ContactRDEpitechDigital } from "Forms/EpitechDigital"
import { ContactISG, DocumentationMscISG, JourneesPortesOuvertesISG } from "Forms/ISG"
import { JourneesPortesOuvertesISGLuxury } from "Forms/ISGLuxury"
import { AtelierSupinfo, ConferenceSupinfo, DocumentationSupinfo, JourneeImmersiveSupinfo, JourneesPortesOuvertesSupinfo } from "Forms/SupInfo"
import { ComonDemainISEG, ContactISEG, DocumentationISEG, GuideMetiersISEG, JourneeImmersionISEG, JourneesPortesOuvertesISEG, PrintempsOrientationISEG, SoireeSpecialeISEG } from "Forms/ISEG"
import { DocumentationESME, JourneesDecouverteESME, JourneesPortesOuvertesESME, RdvOrientationESME } from "Forms/ESME"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Layout from "./components/Theme/Layout"
import { AtelierEartsup, ConferenceEartsup, ContactEartsup, DocumentationEartsup, EvenementGeneriqueEartsup, JourneeImmersionEartsup, JourneePortesOuvertesEartsup, LivreBlancEtudiantEartsup, LivreBlancParentEartsup, PrecandidatureEartsup } from "Forms/Eartsup"
import { AtelierIAInstitut, PrecandidatureIAInstitut } from "Forms/IAInstitut"
import DemoAdresse from "components/Demo/DemoAdresse"
import Cookie from "Forms/Cookie"
import { BachelorDayIPSA, DocumentationIPSA, JourneesDecouverteIPSA, JourneesPortesOuvertesIPSA } from "Forms/IPSA"
import { ConferenceEpita, DocumentationEpita, JourneesDecouverteEpita, JourneesPortesOuvertesEpita } from "Forms/EPITA"
import {LandingPageForm} from "Forms/LandingPage"
import defineWebComponent from "services/customElement"
import { DocumentationAdvance } from "Forms/Advance"
import { BiotechDaysSupbiotech, DocumentationSupbiotech, JourneesPortesOuvertesSupbiotech, TimeTravelSupbiotech } from "Forms/SupBiotech"

function App() {

  let eartsupCampaignEtudiant = '7011x000000sf5hAAA'
  let eartsupCampaignParent = '7011x000000sf5mAAA'

  if (process.env.NODE_ENV === 'production') {
    if (document.location.href.includes('e-artsup.net')) {
      eartsupCampaignEtudiant = '701080000012NxiAAE'
      eartsupCampaignParent = '701080000012NxnAAE'
    }
    if (document.location.href.includes('lead-forms-qa')) {
      eartsupCampaignEtudiant = '7010E000001qhQ1'
      eartsupCampaignParent = '7010E000001qhQ6'
    }
  }

  const villes = ['Bordeaux', 'Lille', 'Lyon', 'Nantes', 'Nice', 'Paris', 'Strasbourg', 'Toulouse']

  const landingPageParams = {
    division: "ESME",
    firstNameRequired: true,
    school: "ESME",
    lastNameRequired: true,
    emailRequired: true,
    phoneNumberRequired: true,
    academicLevelRequired: true,
    campusRequired: true,
    lastForm: "",
    leadSource: "",
    defaultCampaignId: "701Aa00000LKL13",
    status: "",
    description: "",
    subject: "",
    theme: "",
    form: "Documentation",
    recordTypeName: "EngineeringSchoolLead",
    academics: ["Bac +2", "Bac +3", "Bac +4", "Bac +5"],
    campuses: ["Toulouse", "Paris", "Lille", "Bordeaux", "Lyon", "Nantes", "Strasbourg", "Nice"],
    color: "#fff",
    backgroundColor: "#102b65",
    hover: "#ff4d4d",
  }




  return (
    <Router>
      <Layout>
          <Routes>
            <Route exact path="/demo-adresse" element={<DemoAdresse />}></Route>
            <Route exact path="/advance-documentation" element={<DocumentationAdvance />}></Route>
            <Route exact path="/eartsup-documentation" element={<DocumentationEartsup />}></Route>
            <Route exact path="/eartsup-atelier" element={<AtelierEartsup />}></Route>
            <Route exact path="/eartsup-conference" element={<ConferenceEartsup />}></Route>
            <Route exact path="/eartsup-ji" element={<JourneeImmersionEartsup />}></Route>
            <Route exact path="/eartsup-jpo" element={<JourneePortesOuvertesEartsup />}></Route>
            <Route exact path="/eartsup-contact" element={<ContactEartsup />}></Route>
            <Route exact path="/eartsup-precandidature" element={<PrecandidatureEartsup />}></Route>
            <Route exact path="/eartsup-webinar" element={<ConferenceEartsup type="Webinar" />}></Route>
            <Route exact path="/eartsup-event-generique" element={<EvenementGeneriqueEartsup type="Webinar" />}></Route>
            <Route exact path="/eartsup-livreblanc-etudiant" element={<LivreBlancEtudiantEartsup campaignId={eartsupCampaignEtudiant} />}></Route>
            <Route exact path="/eartsup-livreblanc-parent" element={<LivreBlancParentEartsup campaignId={eartsupCampaignParent} />}></Route>
            <Route exact path="/epita-documentation" element={<DocumentationEpita />}></Route>
            <Route exact path="/epita-jpo" element={<JourneesPortesOuvertesEpita />}></Route>
            <Route exact path="/epita-jdmi" element={<JourneesDecouverteEpita />}></Route>
            <Route exact path="/epita-webinar" element={<ConferenceEpita />}></Route>
            <Route exact path="/epitech-digital-documentation" element={<DocumentationEpitechDigital />}></Route>
            <Route exact path="/epitech-digital-digiclub" element={<DigiclubEpitechDigital />}></Route>
            <Route exact path="/epitech-digital-jpo" element={<JourneesPortesOuvertesEpitechDigital />}></Route>
            <Route exact path="/epitech-digital-contact" element={<ContactEpitechDigital />}></Route>
            <Route exact path="/epitech-digital-contact-rd" element={<ContactRDEpitechDigital />}></Route>
            <Route exact path="/esme-documentation" element={<DocumentationESME />}></Route>
            <Route exact path="/esme-jpo" element={<JourneesPortesOuvertesESME />}></Route>
            <Route exact path="/esme-jdmi" element={<JourneesDecouverteESME />}></Route>
            <Route exact path="/esme-rdv-orientation" element={<RdvOrientationESME />}></Route>
            <Route exact path="/iainstitut-atelier" element={<AtelierIAInstitut />}></Route>
            <Route exact path="/iainstitut-precandidature" element={<PrecandidatureIAInstitut />}></Route>
            <Route exact path="/ipsa-documentation" element={<DocumentationIPSA />}></Route>
            <Route exact path="/ipsa-jpo" element={<JourneesPortesOuvertesIPSA />}></Route>
            <Route exact path="/ipsa-jdmi" element={<JourneesDecouverteIPSA />}></Route>
            <Route exact path="/ipsa-bachelor-day" element={<BachelorDayIPSA />}></Route>
            <Route exact path="/epitech-digital-contact" element={<ContactEpitechDigital />}></Route>
            <Route exact path="/iseg-documentation" element={<DocumentationISEG />}></Route>
            <Route exact path="/iseg-jpo" element={<JourneesPortesOuvertesISEG />}></Route>
            <Route exact path="/iseg-journee-immersion" element={<JourneeImmersionISEG />}></Route>
            <Route exact path="/iseg-printemps-orientation" element={<PrintempsOrientationISEG />}></Route>
            <Route exact path="/iseg-soiree-speciale" element={<SoireeSpecialeISEG />}></Route>
            <Route exact path="/iseg-contact" element={<ContactISEG />}></Route>
            {villes.map((ville) => (
              <Route key={ville} path={`/iseg-contact-${ville}`} element={<ContactISEG />}></Route>
            ))}
            {villes.map((ville) => (
              <Route key={ville} exact path={`/iseg-guide-metiers-${ville}`} element={<GuideMetiersISEG />}></Route>
            ))}
            <Route exact path="/iseg-guide-metiers" element={<GuideMetiersISEG />}></Route>
            <Route exact path="/iseg-comon-demain" element={<ComonDemainISEG />}></Route>
            <Route exact path="/isg-contact" element={<ContactISG />}></Route>
            <Route exact path="/isg-documentation-msc" element={<DocumentationMscISG campus="Bordeaux" msc="" productid="TestProductId" />}></Route>
            <Route exact path="/isg-jpo" element={<JourneesPortesOuvertesISG />}></Route>
            <Route exact path="/isg-luxury-jpo" element={<JourneesPortesOuvertesISGLuxury />}></Route>
            <Route exact path="/supbiotech-biotech-days" element={<BiotechDaysSupbiotech />}></Route>
            <Route exact path="/supbiotech-documentation" element={<DocumentationSupbiotech />}></Route>
            <Route exact path="/supbiotech-jpo" element={<JourneesPortesOuvertesSupbiotech />}></Route>
            <Route exact path="/supbiotech-time-travel" element={<TimeTravelSupbiotech />}></Route>
            <Route exact path="/supinfo-documentation" element={<DocumentationSupinfo />}></Route>
            <Route exact path="/supinfo-jpo" element={<JourneesPortesOuvertesSupinfo />}></Route>
            <Route exact path="/supinfo-ji" element={<JourneeImmersiveSupinfo />}></Route>
            <Route exact path="/supinfo-atelier" element={<AtelierSupinfo />}></Route>
            <Route exact path="/supinfo-conference" element={<ConferenceSupinfo />}></Route>
            <Route exact path="/landing-page-form" element={<LandingPageForm/>}></Route>
          </Routes>
      </Layout>
    </Router>
  )
}

export default App;

// e-artsup
defineWebComponent('eartsup-atelier', AtelierEartsup)
defineWebComponent('eartsup-conference', ConferenceEartsup)
defineWebComponent('eartsup-contact', ContactEartsup)
defineWebComponent('eartsup-documentation', DocumentationEartsup)
defineWebComponent('eartsup-ji', JourneeImmersionEartsup)
defineWebComponent('eartsup-jpo', JourneePortesOuvertesEartsup)
defineWebComponent('eartsup-precandidature', PrecandidatureEartsup)
defineWebComponent('eartsup-livre-blanc-etudiant', LivreBlancEtudiantEartsup)
defineWebComponent('eartsup-livre-blanc-parent', LivreBlancParentEartsup)
defineWebComponent('eartsup-webinar', ConferenceEartsup)
defineWebComponent('eartsup-event-generique', EvenementGeneriqueEartsup)

// const EartsupAtelier = reactToWebcomponent(AtelierEartsup, React, ReactDOM)
// customElements.define("eartsup-atelier", EartsupAtelier)

// const EartsupConference = reactToWebcomponent(ConferenceEartsup, React, ReactDOM, { props: { type: "string" } })
// customElements.define("eartsup-conference", EartsupConference)

// const EartsupContact = reactToWebcomponent(ContactEartsup, React, ReactDOM)
// customElements.define("eartsup-contact", EartsupContact)

// const EartsupDocumentation = reactToWebcomponent(DocumentationEartsup, React, ReactDOM)
// customElements.define("eartsup-documentation", EartsupDocumentation)

// const EartsupJourneeImmersion = reactToWebcomponent(JourneeImmersionEartsup, React, ReactDOM)
// customElements.define("eartsup-ji", EartsupJourneeImmersion)

// const EartsupJourneePortesOuvertes = reactToWebcomponent(JourneePortesOuvertesEartsup, React, ReactDOM)
// customElements.define("eartsup-jpo", EartsupJourneePortesOuvertes)

// const EartsupPreCandidature = reactToWebcomponent(PrecandidatureEartsup, React, ReactDOM)
// customElements.define("eartsup-precandidature", EartsupPreCandidature)

// const EartsupLivreBlancEtudiant = reactToWebcomponent(LivreBlancEtudiantEartsup, React, ReactDOM)
// customElements.define("eartsup-livre-blanc-etudiant", EartsupLivreBlancEtudiant)

// const EartsupLivreBlancParent = reactToWebcomponent(LivreBlancParentEartsup, React, ReactDOM)
// customElements.define("eartsup-livre-blanc-parent", EartsupLivreBlancParent)

// EPITA
defineWebComponent('epita-documentation', DocumentationEpita)
defineWebComponent('epita-jdmi', JourneesDecouverteEpita)
defineWebComponent('epita-jpo', JourneesPortesOuvertesEpita)
defineWebComponent('epita-webinar', ConferenceEpita)
// const EpitaConference = reactToWebcomponent(ConferenceEpita, React, ReactDOM)
// customElements.define("epita-webinar", EpitaConference)

// Epitech Digital
defineWebComponent('epitech-digital-documentation', DocumentationEpitechDigital)
defineWebComponent('epitech-digital-digiclub', DigiclubEpitechDigital)
defineWebComponent('epitech-digital-jpo', JourneesPortesOuvertesEpitechDigital)
defineWebComponent('epitech-digital-contact', ContactEpitechDigital)
defineWebComponent('epitech-digital-contact-rd', ContactRDEpitechDigital)
// const EpitechDigitalDocumentation = reactToWebcomponent(DocumentationEpitechDigital, React, ReactDOM)
// customElements.define("documentation-epitech-digital", EpitechDigitalDocumentation)

// const EpitechDigitalJourneesPortesOuvertes = reactToWebcomponent(JourneesPortesOuvertesEpitechDigital, React, ReactDOM)
// customElements.define("jpo-epitech-digital", EpitechDigitalJourneesPortesOuvertes)

// const EpitechDigitalDigiclub = reactToWebcomponent(DigiclubEpitechDigital, React, ReactDOM)
// customElements.define("digiclub-epitech-digital", EpitechDigitalDigiclub)

// const EpitechDigitalContact = reactToWebcomponent(ContactEpitechDigital, React, ReactDOM)
// customElements.define("contact-epitech-digital", EpitechDigitalContact)

// const EpitechDigitalContactRD = reactToWebcomponent(ContactRDEpitechDigital, React, ReactDOM, { props: { campaignId: "string" } })
// customElements.define("contact-rd-epitech-digital", EpitechDigitalContactRD)

// ESME
// const ESMEDocumentation = reactToWebcomponent(DocumentationESME, React, ReactDOM)
// customElements.define("esme-documentation", ESMEDocumentation)
defineWebComponent('esme-documentation', DocumentationESME)
defineWebComponent('esme-jpo', JourneesPortesOuvertesESME)
defineWebComponent('esme-jdmi', JourneesDecouverteESME)
defineWebComponent('esme-rdv-orientation', RdvOrientationESME)

// IA Institut
const IAInstitutAtelier = reactToWebcomponent(AtelierIAInstitut, React, ReactDOM, { props: { elevation: "number", primaryColor: "string", sx: "string" } })
customElements.define('iainstitut-atelier', IAInstitutAtelier)

const IAInstitutPrecandidature = reactToWebcomponent(PrecandidatureIAInstitut, React, ReactDOM, { props: { elevation: "number", primaryColor: "string", sx: "string" } })
customElements.define('iainstitut-precandidature', IAInstitutPrecandidature)

// IPSA
// const IPSADocumentation = reactToWebcomponent(DocumentationIPSA, React, ReactDOM)
// customElements.define("ipsa-documentation", IPSADocumentation)

// const IPSAJourneesPortesOuvertes = reactToWebcomponent(JourneesPortesOuvertesIPSA, React, ReactDOM)
// customElements.define("ipsa-jpo", IPSAJourneesPortesOuvertes)

// const IPSAJourneesDecouverte = reactToWebcomponent(JourneesDecouverteIPSA, React, ReactDOM)
// customElements.define("ipsa-jdmi", IPSAJourneesDecouverte)
defineWebComponent('ipsa-bachelor-day', BachelorDayIPSA)
defineWebComponent('ipsa-documentation', DocumentationIPSA)
defineWebComponent('ipsa-jpo', JourneesPortesOuvertesIPSA)
defineWebComponent('ipsa-jdmi', JourneesDecouverteIPSA)

// ISEG

defineWebComponent('iseg-comon-demain', ComonDemainISEG)
defineWebComponent('iseg-contact', ContactISEG)
defineWebComponent('iseg-documentation', DocumentationISEG)
defineWebComponent('iseg-guide-metiers', GuideMetiersISEG)
defineWebComponent('iseg-journee-immersion', JourneeImmersionISEG)
defineWebComponent('iseg-journees-portes-ouvertes', JourneesPortesOuvertesISEG)
defineWebComponent('iseg-printemps-orientation', PrintempsOrientationISEG)
defineWebComponent('iseg-soiree-speciale', SoireeSpecialeISEG)

// const ISEGContact = reactToWebcomponent(ContactISEG, React, ReactDOM)
// customElements.define("iseg-contact", ISEGContact)

// const ISEGGuideMetiers = reactToWebcomponent(GuideMetiersISEG, React, ReactDOM)
// customElements.define("iseg-guide-metiers", ISEGGuideMetiers)

// const ISEGPrintempsOrientation = reactToWebcomponent(PrintempsOrientationISEG, React, ReactDOM)
// customElements.define("iseg-printemps-orientation", ISEGPrintempsOrientation)

// const ISEGSoireeSpeciale = reactToWebcomponent(SoireeSpecialeISEG, React, ReactDOM)
// customElements.define("iseg-soiree-speciale", ISEGSoireeSpeciale)

// ISG

defineWebComponent('jpo-isg', JourneesPortesOuvertesISG)
defineWebComponent('contact-isg', ContactISG)
defineWebComponent('documentation-isg-msc', DocumentationMscISG)
// const ISGJourneesPortesOuvertes = reactToWebcomponent(JourneesPortesOuvertesISG, React, ReactDOM)
// customElements.define("jpo-isg", ISGJourneesPortesOuvertes)

// const ISGContact = reactToWebcomponent(ContactISG, React, ReactDOM)
// customElements.define("contact-isg", ISGContact)

// const ISGDocumentationMsc = reactToWebcomponent(DocumentationMscISG, React, ReactDOM, { props: { campaignId: "string", campus: "string", msc: "string" } })
// customElements.define("documentation-isg-msc", ISGDocumentationMsc)


// ISG Luxury
const ISGLuxuryDocumentation = reactToWebcomponent(JourneesPortesOuvertesISGLuxury, React, ReactDOM)
customElements.define("isg-luxury-jpo", ISGLuxuryDocumentation)

// Sup'Biotech
defineWebComponent('supbiotech-biotech-days', BiotechDaysSupbiotech)
defineWebComponent('supbiotech-documentation', DocumentationSupbiotech)
defineWebComponent('supbiotech-jpo', JourneesPortesOuvertesSupbiotech)
defineWebComponent('supbiotech-time-travel', TimeTravelSupbiotech)

// SUPINFO
defineWebComponent('supinfo-atelier', AtelierSupinfo)
defineWebComponent('supinfo-conference', ConferenceSupinfo)
defineWebComponent('supinfo-documentation', DocumentationSupinfo)
defineWebComponent('supinfo-journee-immersive', JourneeImmersiveSupinfo)
defineWebComponent('supinfo-jpo', JourneesPortesOuvertesSupinfo)

// generic landing page form
defineWebComponent('landing-page-form', LandingPageForm)

// const SupInfoAtelier = reactToWebcomponent(AtelierSupinfo, React, ReactDOM)
// customElements.define('supinfo-atelier', SupInfoAtelier)

// const SupInfoConference = reactToWebcomponent(ConferenceSupinfo, React, ReactDOM)
// customElements.define('supinfo-conference', SupInfoConference)

// const SupInfoDocumentation = reactToWebcomponent(DocumentationSupinfo, React, ReactDOM)
// customElements.define('supinfo-documentation', SupInfoDocumentation)

// const SupInfoJourneeImmersive = reactToWebcomponent(JourneeImmersiveSupinfo, React, ReactDOM)
// customElements.define('supinfo-journee-immersive', SupInfoJourneeImmersive)

// const SupInfoJourneesPortesOuvertes = reactToWebcomponent(JourneesPortesOuvertesSupinfo, React, ReactDOM)
// customElements.define('supinfo-jpo', SupInfoJourneesPortesOuvertes)

const coooookie = reactToWebcomponent(Cookie, React, ReactDOM)
customElements.define('cookie-test-cookie', coooookie)
